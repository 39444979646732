import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController, IonSlides } from '@ionic/angular';
import { S3imagesService } from '../services/s3images.service';

@Component({
  selector: 'app-product-zoom',
  templateUrl: './product-zoom.page.html',
  styleUrls: ['./product-zoom.page.scss'],
})
export class ProductZoomPage implements OnInit {
  newUrl = this.images3Service.s3url;
  slideOpts = {
    initialSlide: 0,
    // speed: 400,
    // loop: true,
    zoom: {
      minRatio: 1,
      maxRatio: 2,
      toggle: true,
      containerClass :'swiper-zoom-container',
      zoomedSlideClass: 'swiper-slide-zoomed'
    }
  };
  mainimage: any;
  SelectedProduct:any;
  imgDataForHover:any;
  constructor(
    public navParams: NavParams, private images3Service :S3imagesService,
    public modalCtrl: ModalController
  ) {
    this.mainimage  = this.navParams.get('imgurl');
    this.SelectedProduct=this.navParams.get('productName');
    this.imgDataForHover=this.navParams.get('verticalImageList');
   }
  ngOnInit() {
  }


  changeImageBgAsHover(i){
    console.log("222","~Hover~hoveredIndex~"+i)
    this.imgDataForHover[i].isHover=true;
    for(var m=0; m < this.imgDataForHover.length;m++){
       if(m!=i){
        this.imgDataForHover[m].isHover=false;
       } 
       
       if(m==i){
        this.imgDataForHover[m].isHover=true;
       } 
      
    }

    this.mainimage=this.imgDataForHover[i].imgUrl;
  }



  dismiss() {
    this.modalCtrl.dismiss();
  }

}
